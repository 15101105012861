import { For, observer } from '@legendapp/state/react';
import { useNewsContext } from '../contexts/NewsContext';

export const NewsScreen = observer(() => {
  const { news } = useNewsContext();
  return (
    <div className='h-full'>
      <div className='h-full bg-slate-100'>
        <div className='container mx-auto'>
          <div className='flex flex-col items-center justify-center h-full'>
            <h1 className='text-2xl font-bold border-b-2 border-stone-300'>News</h1>
            <For each={news}>
              {(newsItem) => (
                <div key={`NewsItem_${newsItem.key.get()}`} className='p-1 w-full'>
                  <div className='p-4 bg-white rounded shadow-md'>
                    <div className='flex flex-col'>
                      <div className='text-xs font-extrabold text-stone-400'>{newsItem.date.get()}</div>
                      <div className='text-l font-bold text-stone-700'>
                        {newsItem.title?.get()}
                        {newsItem.isDealerItem?.get() && <span className='text-red-800'> - Dealer Note</span>}
                      </div>
                      <p className='pt-1 text-s'>{newsItem.note.get()}</p>
                      <a className='text-sky-700 overflow-hidden' href={newsItem.link?.get()} target={'_blank'}>
                        {newsItem.link?.get()}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </For>
          </div>
        </div>
      </div>
    </div>
  );
});

import { Disclosure } from '@headlessui/react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { appNavigation } from '../App';
import logo from '../HeaderLogo.png';
import { DealerUnlockButton } from './DealerUnlockButton';

export const AppHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Disclosure as='nav' className='bg-gray-800'>
      {({ open }) => (
        <>
          <div className='max-w-7xl px-2 sm:px-6 lg:px-8'>
            {/* <FontAwesomeIcon
              icon={faArrowAltCircleLeft as any}
              className='h-6 w-6 text-slate-700 hover:text-slate-400'
              onClick={() => {
                navigation(-1);
              }}
            /> */}
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <DealerUnlockButton>
                  <div className='flex flex-shrink-0 items-center'>
                    <img className='block h-8 w-auto lg:hidden' src={logo} alt='Staheli West' />
                    <img className='hidden h-8 w-auto lg:block' src={logo} alt='Staheli West' />
                  </div>
                </DealerUnlockButton>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {appNavigation.map((item) => (
                      <Disclosure.Button>
                        <NavLink
                          to={item.path}
                          className={({ isActive }) => {
                            return (
                              (isActive
                                ? 'text-white bg-gray-900 '
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white ') +
                              'px-3 py-2 rounded-md text-sm font-medium'
                            );
                          }}
                        >
                          {item.label}
                        </NavLink>
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='flex flex-col space-y-1 px-2 pt-2 pb-3'>
              {appNavigation.map((item) => {
                const isActive = matchPath(item.path, location.pathname);
                return (
                  <Disclosure.Button onClick={() => navigate(item.path)} className={'flex'}>
                    <div
                      className={
                        (isActive ? 'text-white bg-gray-900 ' : 'text-gray-300 hover:bg-gray-700 hover:text-white ') +
                        'flex-auto px-3 py-2 rounded-md text-base font-medium'
                      }
                    >
                      {item.label}
                    </div>
                  </Disclosure.Button>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

interface IExpandableSectionProps {
  title: string;
  content: () => JSX.Element;
}

export const ExpandableSection = (props: IExpandableSectionProps) => {
  return (
    <div>
      <Disclosure defaultOpen={props.title === 'Favorites'}>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex w-full justify-between rounded-lg bg-gray-300 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75'>
              <span>{props.title}</span>
              <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
            </Disclosure.Button>
            <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-gray-500'>{props.content}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className='mt-2' />
    </div>
  );
};

import { ObservableObject } from '@legendapp/state';
import { For, observer } from '@legendapp/state/react';
import { ExpandableSection } from '../components/ExpandableSection';
import { ManualsListItem } from '../components/ManualsListItem';
import { useManualsContext } from '../contexts/ManualsContext';
import { IListItem } from '../models/interfaces';

export const ManualsScreen = observer(() => {
  const { manuals } = useManualsContext();

  return (
    <div className='p-4 flex-1'>
      <For each={manuals}>
        {(manualGroup) => (
          <ExpandableSection
            title={manualGroup.groupTitle.get()}
            content={() => (
              <div className='border rounded-l bg-white shadow-md'>
                <For each={manualGroup.pdfs}>
                  {(manual) => (
                    <div>
                      <ManualsListItem key={(manual.peek() as any).id} manual={manual as ObservableObject<IListItem>} />
                      {manualGroup.pdfs.indexOf(manual as any) + 1 !== manualGroup.pdfs.length && (
                        <div className='border-b-2' />
                      )}
                    </div>
                  )}
                </For>
              </div>
            )}
          />
        )}
      </For>
    </div>
  );
});

import { Outlet } from 'react-router-dom';
import { AppHeader } from '../components/AppHeader';
import { DealerUnlockButton } from '../components/DealerUnlockButton';
import { ContactsContext } from '../contexts/ContactsContext';
import { ManualsContext } from '../contexts/ManualsContext';
import { NewsContext } from '../contexts/NewsContext';
import { VideosContext } from '../contexts/VideosContext';
import { LoginModal } from '../components/LoginModal';
import { useAppStateContext } from '../contexts/AppStateContext';

export const RootScreen = () => {
  const context = useAppStateContext();
  return (
    <div className='flex flex-col h-full overflow-hidden bg-slate-100'>
      <AppHeader />
      <div className='flex-1 overflow-scroll'>
        <ManualsContext>
          <VideosContext>
            <ContactsContext>
              <NewsContext>
                <Outlet />
              </NewsContext>
            </ContactsContext>
          </VideosContext>
        </ManualsContext>
      </div>
      {/* <DealerUnlockButton /> */}
      {context.isDealerLoginModalOpen && <LoginModal />}
    </div>
  );
};

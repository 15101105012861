import { For } from '@legendapp/state/react';
import { ExpandableSection } from '../components/ExpandableSection';
import { VideoListItem } from '../components/VideoListItem';
import { useVideosContext } from '../contexts/VideosContext';

export const VideosScreen = () => {
  const { videos } = useVideosContext();
  return (
    <div className='p-4 flex-1'>
      <For each={videos}>
        {(videoGroup) => (
          <ExpandableSection
            title={videoGroup.groupTitle.get()}
            content={() => (
              <div className='border rounded bg-white shadow-md'>
                <For each={videoGroup.videos}>
                  {(video) => (
                    <div key={(video.peek() as any).key}>
                      <VideoListItem video={video as any} />
                      {videos.peek().indexOf(video as any) + 1 !== videoGroup.videos.length && (
                        <div className='border-b-2' />
                      )}
                    </div>
                  )}
                </For>
              </div>
            )}
          />
        )}
      </For>
    </div>
  );
};

import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppStateContext } from './contexts/AppStateContext';
import { RootScreen } from './routes/RootScreen';
import { ErrorScreen } from './routes/ErrorScreen';
import { ManualsScreen } from './routes/ManualsScreen';
import { loader as manualScreenLoader, ManualScreen } from './routes/ManualScreen';
import { VideosScreen } from './routes/VideosScreen';
import { loader as videoScreenLoader, VideoScreen } from './routes/VideoScreen';
import { ContactsScreen } from './routes/ContactsScreen';
import { NewsScreen } from './routes/NewsScreen';
import { PrivacyPolicy } from './routes/PrivacyPolicy';

export const appNavigation = [
  {
    label: 'Manuals',
    path: '/',
    default: true,
    element: <ManualsScreen />,
  },
  {
    label: 'Videos',
    path: '/videos',
    element: <VideosScreen />,
  },
  {
    label: 'Contacts',
    path: '/contacts',
    element: <ContactsScreen />,
  },
  {
    label: 'News',
    path: '/news',
    element: <NewsScreen />,
  },
];

export const appRoutes = [
  {
    label: 'Manual',
    path: '/manuals/manual/:id',
    element: <ManualScreen />,
    loader: manualScreenLoader,
  },
  {
    label: 'Video',
    path: '/videos/video/:id',
    element: <VideoScreen />,
    loader: videoScreenLoader,
  },
];

const routes = createBrowserRouter([
  {
    path: '/',
    element: <RootScreen />,
    errorElement: <ErrorScreen />,
    children: [...appNavigation, ...appRoutes],
  },
  {
    path: '/privacy',
    element: <PrivacyPolicy />,
  },
  // {
  //   path: '/.well-known/apple-app-site-association',
  //   element: JSON.stringify({
  //     applinks: {
  //       details: [
  //         {
  //           appIDs: ['U2WVBB48N8.com.StaheliWest'],
  //           components: [
  //             {
  //               '/': '*',
  //               comment: 'Matches all urls.',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     webcredentials: {
  //       apps: ['U2WVBB48N8.com.StaheliWest'],
  //     },
  //   }),
  // loader: () => window.location.replace('/.well-known/apple-app-site-association.json'),
  // },
]);

function App() {
  return (
    <AppStateContext>
      <RouterProvider router={routes} />
    </AppStateContext>
  );
}

export default App;
